import React, { FC } from "react";
import classNames from "classnames";
import Image, { ImageProps } from "@components/cms/Image";
import Heading from "@components/common/Heading";

type Props = {
  text?: string;
  image?: ImageProps;
  headline?: string;
  subheadline?: string;
};

const Teaser: FC<Props> = ({ text, image, headline , subheadline}) => {
  return (
    <div className={"mb-4 grid gap-4 md:grid-cols-12"}>
      {image && (
        <div className={"col-span-12 md:col-span-5"}>
          <Image alt={image.alt} filename={image.filename} />
        </div>
      )}
      <div className={classNames("col-span-12", { "md:col-span-7": image })}>
        <Heading element={"h2"} className={"pb-3"}>
          {headline}
        </Heading>
        <Heading element={"h6"} className={"pb-3"}>
          {subheadline}
        </Heading>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Teaser;
