import React, { FC, ReactNode } from "react";
import { useTranslation } from "next-i18next";
import Heading from "@components/common/Heading";
import Breadcrumb from "@components/common/Breadcrumb";
import Meta from "@components/layout/Meta";

type Props = {
  children: ReactNode;
  title: string;
};

const Page: FC<Props> = ({ title, children }) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <Meta title={title} />
      <Breadcrumb>
        <Breadcrumb.Item href={"/"}>{t("common:home.title")}</Breadcrumb.Item>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <section>
        <header>
          <Heading className={"mb-6"}>{title}</Heading>
        </header>
        <div>{children}</div>
      </section>
    </>
  );
};

export default Page;
