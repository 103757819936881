import React, { FC, ReactNode } from "react";
import Head from "next/head";
import config from "@lib/config";

interface LinkTag {
  rel: string;
  href: string;
  sizes?: string;
  media?: string;
  type?: string;
  color?: string;
  keyOverride?: string;
  as?: string;
}

interface SeoProps {
  title?: string | null;
  titleTemplate?: string;
  defaultTitle?: string;
  themeColor?: string;
  noindex?: boolean;
  nofollow?: boolean;
  description?: string | null;
  canonical?: string;
  additionalLinkTags?: ReadonlyArray<LinkTag>;
}

type Props = {
  children?: never;
} & SeoProps;

const defaults = {
  title: config.title || "",
  templateTitle: config.titleTemplate || "%s",
  themeColor: config.themeColor || "#ffffff",
  noindex: false,
  nofollow: false,
};

const buildTags = (config: SeoProps) => {
  const tagsToRender: ReactNode[] = [];

  if (config.titleTemplate) {
    defaults.templateTitle = config.titleTemplate;
  }

  let updatedTitle = "";
  if (config.title) {
    updatedTitle = config.title;
    if (defaults.templateTitle) {
      updatedTitle = defaults.templateTitle.replace(/%s/g, () => updatedTitle);
    }
  } else if (config.defaultTitle) {
    updatedTitle = config.defaultTitle;
  }

  if (updatedTitle) {
    tagsToRender.push(<title key="title">{updatedTitle}</title>);
  }

  const noindex = config.noindex || defaults.noindex;
  const nofollow = config.nofollow || defaults.nofollow;

  if (noindex || nofollow) {
    tagsToRender.push(
      <meta
        key="robots"
        name="robots"
        content={`${noindex ? "noindex" : "index"},${
          nofollow ? "nofollow" : "follow"
        }`}
      />
    );
  } else {
    tagsToRender.push(
      <meta key="robots" name="robots" content={`index,follow`} />
    );
  }

  if (config.description) {
    tagsToRender.push(
      <meta key="description" name="description" content={config.description} />
    );
  }

  if (config.themeColor) {
    tagsToRender.push(
      <meta key="theme-color" name="theme-color" content={config.themeColor} />
    );
  }

  if (config.canonical) {
    tagsToRender.push(
      <link rel="canonical" href={config.canonical} key="canonical" />
    );
  }

  if (config.additionalLinkTags?.length) {
    config.additionalLinkTags.forEach((tag) => {
      tagsToRender.push(
        <link key={`link${tag.keyOverride ?? tag.href}${tag.rel}`} {...tag} />
      );
    });
  }

  return tagsToRender;
};

const Meta: FC<Props> = (props) => {
  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      {buildTags(props)}
    </Head>
  );
};

Meta.defaultProps = defaults;

export default Meta;
