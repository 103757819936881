import React, {
  Children,
  cloneElement,
  FC,
  FunctionComponent,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";
import NextLink from "next/link";
import { LinkProps } from "next/dist/client/link";
import { UrlObject } from "url";
import classNames from "classnames";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

type ItemProps = {
  isCurrent?: boolean;
  href?: string | UrlObject;
  children: ReactNode;
} & Omit<LinkProps, "href">;

type BreadcrumbComponent = {
  Item: FC<ItemProps>;
} & FunctionComponent<HTMLAttributes<HTMLElement>>;

const Breadcrumb: BreadcrumbComponent = ({ children, className, ...props }) => {
  return (
    <nav
      className={classNames("flex mb-6", className)}
      {...props}
      aria-label="breadcrumb"
    >
      <ol className={"inline-flex items-center space-x-1 md:space-x-3"}>
        {Children.map(children, (child, index) => {
          const item = child as ReactElement<PropsWithChildren<ItemProps>>;
          return cloneElement(item, {
            isCurrent: index === Children.count(children) - 1,
          });
        })}
      </ol>
    </nav>
  );
};

const Item: FC<ItemProps> = ({ isCurrent, children, href, ...props }) => {
  return (
    <>
      {!isCurrent && href ? (
        <li className={"inline-flex items-center"}>
          <NextLink
            className={
              "inline-flex items-center pr-2 text-sm font-medium hover:text-gray-700 focus:text-gray-700"
            }
            href={href}
            {...props}
          >
            {children}
          </NextLink>
          <ChevronRightIcon className={"inline-block h-4 w-4"} />
        </li>
      ) : (
        <li aria-current="page" className={"inline-flex items-center"}>
          <span className={"text-sm font-medium text-gray-700"}>
            {children}
          </span>
        </li>
      )}
    </>
  );
};

Breadcrumb.Item = Item;

export default Breadcrumb;
