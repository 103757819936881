import {
  Children,
  cloneElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";

type UseChildrenWithProps = {
  children: ReactNode;
};

export const useChildrenWithProps = <T extends any>({
  children,
  ...props
}: UseChildrenWithProps) => {
  const childrenWithProps = Children.map(children, (child) => {
    const item = child as ReactElement<PropsWithChildren<UseChildrenWithProps>>;
    return cloneElement(item, { ...props });
  });

  return childrenWithProps as ReactNode;
};
