import { useEffect, useState } from "react";
import { useCart } from "@components/provider/UseCart";

type UseCartCount = {
  counter: number;
};

export const useCartCount = (): UseCartCount => {
  const [counter, setCounter] = useState<number>(0);
  const { cartData: cart } = useCart();

  useEffect(() => {
    if (cart.cartItems) {
      const sum = cart.cartItems
        .map((item) => {
          return item.quantity;
        })
        .reduce((partialSum, a) => partialSum + a, 0);
      setCounter(sum);
    } else {
      setCounter(0);
    }
  }, [cart]);

  return { counter };
};
