import React, { FC, HTMLAttributes } from "react";
import classNames from "classnames/bind";

type Props = {
  element?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
} & HTMLAttributes<HTMLHeadingElement>;

const Heading: FC<Props> = ({ children, element, className }) => {
  const styles = {
    h1: "text-4xl md:text-5xl",
    h2: "text-3xl md:text-4xl",
    h3: "text-2xl md:text-3xl",
    h4: "text-xl md:text-2xl",
    h5: "text-lg md:text-xl",
    h6: "text-base md:text-lg",
  };
  let cx = classNames.bind(styles);

  const Element = element || "h1";
  
  return (
    <Element className={cx(className, [Element], "font-bold hyphens-auto")}>
      {children}
    </Element>
  );
};

export default Heading;
