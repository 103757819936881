import React, { createElement, Fragment, ReactNode } from "react";
import Grid from "@components/cms/Grid";
import Column from "@components/cms/Column";
import Page from "@components/cms/Page";
import Article from "@components/cms/Article";
import Teaser from "@components/cms/Teaser";
import Image from "@components/cms/Image";
import Banner from "@components/cms/Banner";
import Menu from "@components/cms/Menu";

const Components: { [key: string]: any } = {
  page: Page,
  grid: Grid,
  column: Column,
  article: Article,
  teaser: Teaser,
  image: Image,
  banner: Banner,
  menu: Menu,
};

export type Block = {
  _uid: string;
  children?: Block[];
  component: string;
  _editable: string;
} | null;

const renderer = (block: Block): ReactNode => {
  function renderChildren(children: Block[] | undefined) {
    return Array.isArray(children)
      ? children.map(renderer)
      : renderer(children ?? null);
  }

  if (!block) return null;
  if (typeof Components[block.component] !== "undefined") {
    return createElement(
      Components[block.component],
      {
        key: block._uid,
        ...block,
      },
      renderChildren(block.children)
    );
  }
  if (block.component === "content") {
    return createElement(
      Fragment,
      {
        key: block._uid,
      },
      renderChildren(block.children)
    );
  }
  return createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: block._uid }
  );
};

export default renderer;
