import React, {
  createContext,
  FC,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  useContext,
} from "react";
import Link from "next/link";
import classNames from "classnames";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useToggle } from "@lib/hooks/useToggle";

type BrandProps = {
  href: string;
  children?: ReactNode;
} & HTMLAttributes<HTMLAnchorElement>;

type NavbarComponent = {
  Brand: FC<BrandProps>;
  Toggle: FC<HTMLAttributes<HTMLButtonElement>>;
  Collapse: FC<HTMLAttributes<HTMLDivElement>>;
} & FunctionComponent<HTMLAttributes<HTMLElement>>;

interface NavbarContextType {
  onToggle: () => void;
  expanded: boolean;
}

const NavbarContext = createContext<NavbarContextType | null>(null);

const Navbar: NavbarComponent = ({ children, className }) => {
  const [expand, setExpand] = useToggle();
  const navbarContext: NavbarContextType = {
    onToggle: () => setExpand(),
    expanded: expand,
  };

  return (
    <NavbarContext.Provider value={navbarContext}>
      <nav
        className={classNames(
          "flex w-full flex-wrap items-center justify-between py-2 px-3 md:px-0",
          className
        )}
      >
        {children}
      </nav>
    </NavbarContext.Provider>
  );
};

const Brand: FC<BrandProps> = ({ href, children, className }): JSX.Element => (
  <Link
    className={classNames("py-2 md:px-3 lg:px-0 lg:pr-3", className)}
    href={href}
  >
    {children}
  </Link>
);

const Toggle: FC<HTMLAttributes<HTMLButtonElement>> = ({ className }) => {
  const { onToggle, expanded } = useContext(NavbarContext) || {};

  return (
    <button
      className={classNames(className, "md:hidden py-2")}
      type={"button"}
      aria-expanded={expanded}
      aria-label={"Toggle navigation"}
      onClick={onToggle}
    >
      {expanded ? (
        <XMarkIcon className="block h-6 w-6" aria-hidden={"true"} />
      ) : (
        <Bars3Icon className="block h-6 w-6" aria-hidden={"true"} />
      )}
    </button>
  );
};

const Collapse: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
}) => {
  const { expanded } = useContext(NavbarContext) || {};

  return (
    <div
      className={classNames(
        expanded ? "visible" : "invisible",
        "fixed bg-white z-50 w-11/12 max-w-sm h-screen top-0 left-0 border-r md:max-w-full md:visible md:relative md:bg-transparent md:z-auto md:w-auto md:h-auto md:border-0",
        className
      )}
    >
      <div className={"p-3 md:p-0"}>
        <div className={"flex"}>
          <Toggle className={"ml-auto"} />
        </div>
        {children}
      </div>
    </div>
  );
};

Navbar.Brand = Brand;
Navbar.Toggle = Toggle;
Navbar.Collapse = Collapse;

export default Navbar;
