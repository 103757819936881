import React from "react";
import { useCartCount } from "@lib/hooks/useCartCount";

const CartCounter = () => {
  const { counter } = useCartCount();

  return (
    <div className="absolute -top-2 -right-4 inline-flex h-6 w-6 items-center justify-center rounded-full border border-black bg-gray-200 text-xs">
      {counter}
    </div>
  );
};

export default CartCounter;
