import React, { FC, ReactElement, useState } from "react";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import Header from "@components/layout/Header";
import Footer from "@components/layout/Footer";
import Logo from "@components/layout/Logo";
import Navbar from "@components/common/Navbar";
import SearchForm from "@components/search/SearchForm";
import { ShoppingCartIcon, UserIcon } from "@heroicons/react/24/outline";
import CartCounter from "@components/cart/CartCounter";
import OffCanvas from "@components/common/OffCanvas";
import AccountMenu from "@components/account/AccountMenu";
import Heading from "@components/common/Heading";
import AccountSignInForm from "@components/account/AccountSignInForm";
import renderer from "@components/cms/Components";

type Props = {
  children: ReactElement;
};

const DefaultLayout: FC<Props> = ({ children }) => {
  const { t } = useTranslation(["common", "account"]);
  const [show, setShow] = useState(false);
  const { data: session, status } = useSession();

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <div className="flex h-screen flex-col justify-between lg:container lg:mx-auto">
      <Header>
        <Navbar>
          <Navbar.Toggle />
          <Navbar.Brand href={"/"} className={"md:order-1"}>
            <Logo />
          </Navbar.Brand>
          <div className={"flex md:order-3 md:px-3"}>
            <button
              onClick={toggleShow}
              className={"py-2 pr-3"}
              role={"button"}
            >
              <UserIcon className={"block h-6 w-6"} />
              <span className={"sr-only"}>{t("common:navbar.account")}</span>
            </button>
            <Link href={"/cart"} className={"relative mr-4 py-2"}>
              <ShoppingCartIcon className={"block h-6 w-6"} />
              <span className={"sr-only"}>{t("common:navbar.cart")}</span>
              <CartCounter />
            </Link>
          </div>
          <SearchForm className={"md:order-2 md:flex-1"} />
          {renderer(children.props?.navigationContent?.pageContent)}
        </Navbar>
      </Header>
      <main className={"flex-1 px-3 lg:px-0"}>{children}</main>
      <Footer />
      <OffCanvas show={show} onClose={toggleShow}>
        <OffCanvas.Header>
          <Heading element={"h5"}>{t("common:navbar.account")}</Heading>
        </OffCanvas.Header>
        <OffCanvas.Body>
          {session ? (
            <AccountMenu />
          ) : (
            <AccountSignInForm className={"grid gap-6"} />
          )}
        </OffCanvas.Body>
      </OffCanvas>
    </div>
  );
};

export default DefaultLayout;
