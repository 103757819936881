import React, { FC, HTMLAttributes, useState } from "react";
import { FormikHelpers, useFormik } from "formik";
import { signIn } from "next-auth/react";
import * as Yup from "yup";
import { useTranslation } from "next-i18next";
import { useCart } from "@components/provider/UseCart";
import classNames from "classnames";
import Link from "next/link";
import Button from "@components/common/Button";
import InputText from "@components/common/InputText";

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "account:sign-in.form.msg.password")
    .required("account:sign-in.form.error.password"),
  email: Yup.string()
    .email("account:sign-in.form.msg.email")
    .required("account:sign-in.form.error.email"),
});

const accountLoginFields = {
  email: "",
  password: "",
};

const AccountSignInForm: FC<HTMLAttributes<HTMLFormElement>> = ({
  className,
}) => {
  const { t } = useTranslation(["account"]);
  const { cartData } = useCart();
  const [error, setError] = useState<string | null>(null);
  const { errors, values, touched, handleChange, handleSubmit, isSubmitting } =
    useFormik({
      initialValues: accountLoginFields,
      validationSchema: LoginSchema,
      onSubmit: submitForm,
    });

  async function submitForm(
    values: typeof accountLoginFields,
    { setSubmitting, resetForm }: FormikHelpers<typeof accountLoginFields>
  ) {
    setError(null);
    const response = await signIn("credentials", {
      redirect: false,
      email: values.email,
      password: values.password,
      cartId: cartData.id,
      callbackUrl: `${window.location.origin}`,
    });
    resetForm({ values: accountLoginFields });
    setSubmitting(false);
    if (response?.error) {
      setError(t("account:sign-in.form.error.response"));
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={classNames(className, "mb-6")}>
        <div>
          <InputText
            type={"email"}
            id={"email"}
            name={"email"}
            label={t("account:sign-in.form.label.email")}
            fullWidth
            value={values.email}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            message={
              touched.email &&
              errors.email !== undefined &&
              t(`${errors.email}` as unknown as TemplateStringsArray)
            }
          />
        </div>
        <div>
          <InputText
            type={"password"}
            id={"password"}
            name={"password"}
            label={t("account:sign-in.form.label.password")}
            fullWidth
            value={values.password}
            onChange={handleChange}
            error={touched.password && Boolean(errors.password)}
            message={
              touched.password &&
              errors.password !== undefined &&
              t(`${errors.password}` as unknown as TemplateStringsArray)
            }
            autoComplete={"current-password "}
          />
        </div>
        <Button type={"submit"} variant={"primary"} disabled={isSubmitting}>
          {isSubmitting
            ? t("account:sign-in.form.is-submitting")
            : t("account:sign-in.form.submit")}
        </Button>
        {error && <div className={"my-2 text-red-700"}>{error}</div>}
      </form>
      <Link href={"/account/sign-up"}>{t("account:sign-in.new-account")}</Link>
    </>
  );
};

export default AccountSignInForm;
