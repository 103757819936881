import React, {
  createContext,
  FC,
  FunctionComponent,
  HTMLAttributes,
  useContext,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import { XMarkIcon } from "@heroicons/react/24/outline";

type OffCanvasProps = {
  position?: "right" | "left";
  show?: boolean;
  element?: "aside" | "div";
  onClose?: () => void;
} & HTMLAttributes<HTMLElement>;

type OffCanvasComponent = {
  Header: FC<HTMLAttributes<HTMLDivElement>>;
  Body: FC<HTMLAttributes<HTMLDivElement>>;
} & FunctionComponent<OffCanvasProps>;

const defaultOffCanvasContext = {
  position: "right",
  onClose: () => {},
};

const CanvasContext = createContext<typeof defaultOffCanvasContext>(
  defaultOffCanvasContext
);

const OffCanvas: OffCanvasComponent = ({
  position,
  show,
  element,
  onClose,
  children,
  className,
}) => {
  const Element = element || "aside";
  const offCanvasPosition = position || "right";
  const offCanvasContext = {
    position: offCanvasPosition,
    onClose: onClose ? onClose : () => {},
  };
  const styles = {
    left: "left-0 border-r",
    right: "right-0 border-l",
  };

  return (
    <CanvasContext.Provider value={offCanvasContext}>
      <Element
        tabIndex={-1}
        className={classNames(
          show ? "visible" : "invisible",
          "fixed bg-white z-50 w-11/12 max-w-sm h-screen top-0",
          styles[offCanvasPosition],
          className
        )}
      >
        {children}
      </Element>
    </CanvasContext.Provider>
  );
};

const Header: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
}) => {
  const { position, onClose } = useContext(CanvasContext);
  const [style, setStyle] = useState<string>("");

  useEffect(() => {
    const styles = {
      left: "flex-row-reverse",
      right: "flex-row",
    };

    (Object.keys(styles) as (keyof typeof styles)[]).find((key) => {
      if (key === position) {
        setStyle(styles[key]);
      }
    });
  }, [position]);

  return (
    <div
      className={classNames(
        "flex p-3 items-center justify-between",
        style,
        className
      )}
    >
      <button className={"py-2"} onClick={onClose}>
        <XMarkIcon className={"block h-6 w-6"} aria-hidden={"true"} />
      </button>
      <div className={"self-center"}>{children}</div>
    </div>
  );
};

const Body: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => {
  return <div className={classNames("px-3 pb-3", className)}>{children}</div>;
};

OffCanvas.Header = Header;
OffCanvas.Body = Body;

export default OffCanvas;
