import React, { FC } from "react";
import Navbar from "@components/common/Navbar";
import Nav from "@components/common/Nav";

type Menu = {
  name: string;
  menu_items: MenuLinkItemType[];
};

type MenuLinkItemType = {
  link: MenuLink;
  name: string;
};

type MenuLink = {
  url: string;
};

const Menu: FC<Menu> = ({ name, menu_items}) => {
  return (
    <Navbar.Collapse
      className={"md:order-4 md:flex md:w-full md:justify-center"}
    >
      <Nav
        element={"ul"}
        direction={"vertical"}
        className={
          "md:flex-row md:space-y-0 md:space-x-10 md:text-xl lg:space-x-20"
        }
      >
        {menu_items?.map((item, index) => (
          <Nav.Item element={"li"} key={index} className={"md:self-center"}>
            <MenuLinkItem
              link={item.link}
              name={item.name}
            ></MenuLinkItem>
          </Nav.Item>
        ))}
      </Nav>
    </Navbar.Collapse>
  );
};

const MenuLinkItem: FC<MenuLinkItemType> = ({ link, name }) => {
  return <Nav.Link href={link.url}>{name}</Nav.Link>;
};

export default Menu;
