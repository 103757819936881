import React from "react";
import Image from "next/image";
import config from "@lib/config";
import LogoPng from "@assets/images/logo.png";

const Logo = () => {
  return (
    <Image
      src={LogoPng}
      alt={config.companyName}
      className={"max-w-[120px] lg:max-w-[120px]"}
      priority
    />
  );
};

export default Logo;
