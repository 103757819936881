import React, { FC, FunctionComponent, HTMLAttributes, ReactNode } from "react";
import NextLink from "next/link";
import { LinkProps as NextLinkProps } from "next/dist/client/link";
import classNames from "classnames";
import { useChildrenWithProps } from "@lib/hooks/useChildrenWithProps";

type ChildrenProps = {
  direction?: "horizontal" | "vertical";
};

type NavProps<T = {}> = T & {
  element?: "nav" | "ul" | "ol" | "div";
} & HTMLAttributes<HTMLElement>;

type ItemProps<T = {}> = T & {
  element?: "li" | "div";
} & HTMLAttributes<HTMLElement>;

type LinkProps<T = {}> = T & {
  className?: string;
  children: ReactNode | string;
} & NextLinkProps;

type NavComponent = {
  Item: FC<ItemProps<ChildrenProps>>;
  Link: FC<LinkProps<ChildrenProps>>;
} & FunctionComponent<NavProps<ChildrenProps>>;

const Nav: NavComponent = ({
  element = "nav",
  direction = "horizontal",
  children,
  className,
}) => {
  const Element = element;
  const style = {
    horizontal: "flex-row space-x-8",
    vertical: "flex-col space-y-3",
  };

  return (
    <Element className={classNames("flex", style[direction], className)}>
      {useChildrenWithProps<ChildrenProps>({ children, ...{ direction } })}
    </Element>
  );
};

const Item: FC<ItemProps<ChildrenProps>> = (props) => {
  const { direction, element, children, className } = props;
  const Element = element || "div";

  return (
    <Element className={className}>
      {useChildrenWithProps<ItemProps<ChildrenProps>>({
        children,
        ...{ direction },
      })}
    </Element>
  );
};

const Link: FC<LinkProps<ChildrenProps>> = (props) => {
  const { children, className, direction = "horizontal", ...restProps } = props;
  const style = {
    horizontal: "px-3",
    vertical: "py-3",
  };

  return (
    <NextLink
      {...restProps}
      className={classNames(
        className,
        style[direction],
        "hover:text-gray-700 focus:text-gray-700"
      )}
    >
      {children}
    </NextLink>
  );
};

Nav.Item = Item;
Nav.Link = Link;

export default Nav;
