import React, { FC, HTMLAttributes } from "react";
import { useTranslation } from "next-i18next";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

type Props = HTMLAttributes<HTMLFormElement>;

const SearchForm: FC<Props> = ({ className }) => {
  const { t } = useTranslation(["search"]);

  return (
    <form
      action="/search"
      method="get"
      className={classNames("relative mt-2 w-full md:px-3 md:mt-0", className)}
      role="search"
    >
      <label className={"sr-only"} htmlFor="search">
        {t("search:form.label")}
      </label>
      <input
        type="search"
        id="search"
        name="search"
        placeholder={t("search:form.placeholder")}
        className={"w-full rounded border-black pr-8"}
        required
      />
      <button type="submit" className={"absolute right-5 top-2.5"}>
        <MagnifyingGlassIcon className={"block h-6 w-6"} />
        <span className={"sr-only"}>{t("search:form.submit")}</span>
      </button>
    </form>
  );
};

export default SearchForm;
